import React from "react";
import { graphql } from "gatsby";
import { resolveImage, resolveAboveCta } from "@dataResolvers";
import { Page as PageTemplate } from "@pageTemplates";
import QueryContainer from "@templates/QueryContainer";

export const query = graphql`
  query TransmittersQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "transmittersIndex", siteId: $siteId) {
        ... on Craft_transmittersIndex_transmitters_Entry {
          title
          url
          heroTitle
          entrySubtitle
          intro
          heading
          subHeading
          componentsAccessories {
            ... on Craft_componentAccessories_componentAccessory_Entry {
              title
              price
            }
          }
          metaPageTitle
          metaDescription
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          aboveFooterCtaImageBlocks {
            ...AboveFooterFragment
          }
        }
      }
      entries(section: "transmittersPage", siteId: $siteId) {
        ... on Craft_transmittersPage_transmitter_Entry {
          title
          summary
          url
          learnMoreText
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          transmitters {
            ... on Craft_transmitters_transmitter_Entry {
              title
              productId
              colorHex
              colorName
              colorNameMakeDark
              price
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  const {
    title,
    heroTitle,
    entrySubtitle,
    intro,
    heading,
    subHeading,
    componentsAccessories,
    aboveFooterCtaImageBlocks,
    metaFeaturedImage,
    metaDescription,
  } = entry;

  return {
    title,
    hero: {
      title: heroTitle || title,
      subtitle: entrySubtitle,
      image: resolveImage(metaFeaturedImage),
    },
    intro: {
      copy: intro,
    },
    transmitters: entries?.map((category, i) => {
      return {
        heading: category?.title,
        summary: category?.summary,
        image: resolveImage(category?.metaFeaturedImage),
        fullWidthMobile: true,
        spacingBottom: true,
        width: i < 3 ? "third" : "fourth",
        centerTitle: true,
        fullWidthImage: true,
        imageUrl: category?.url,
        transmitters: category?.transmitters,
        learnMoreText: category?.learnMoreText,
        imageClass: "pb-[62.46%] ",
        imageFill: true,
      };
    }),
    cards: resolveAboveCta(aboveFooterCtaImageBlocks),
    accessories: {
      heading,
      subheading: subHeading,
      componentsAccessories,
    },
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
